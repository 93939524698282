<template>
    <v-app>
        <v-fade-transition>
            <router-view :key="$route.fullPath" />
        </v-fade-transition>
        <v-snackbar :timeout="$config.snackbar.time" :color="$config.snackbar.color" v-model="$config.snackbar.show" bottom >
            {{ $config.snackbar.text }}
        </v-snackbar>
        <!--<a id="sac-flow" @click="$sac.startFlowSac()" v-if="this.$route.name != 'Checkout'" class="animate__animated animate__fadeIn animate__delay-1s">
            <img v-if="!($config.mobile() || $config.responsive() < 991) && $axios.getLocal('lang').data" :src="require(`@/assets/images/sacDesktop-${$axios.getLocal('lang').data}.png`)" :style="'max-width: 140px; position: fixed; right: 30px; bottom: 7%; cursor: pointer; z-index: 200'" />
            <img v-else-if="($config.mobile() || $config.responsive() < 991) && $axios.getLocal('lang').data" :src="require(`@/assets/images/sacDesktop-${$axios.getLocal('lang').data}.png`)" :style="$carrinho.resumo.valor > 0 ? 'max-width: 135px!important; position: fixed; right: 10px; bottom: 12%; cursor: pointer; z-index: 200' : 'max-width: 135px; position: fixed; right: 10px; bottom: 12%; cursor: pointer; z-index: 200'" />
            <img v-else :src="require(`@/assets/images/sacDesktop-pt-BR.png`)" :style="'max-width: 135px; position: fixed; right: 30px; bottom: 7%; cursor: pointer; z-index: 200'" />
        </a>-->

        <Modal/>
        <Loading/>
    </v-app>
</template>
<script>
    import "@/plugins/service/index.js";
    import "@/controllers/DeskManager.controller.js"
    import { ENDPOINTS } from '@/plugins/service/Endpoints.js'
    import Loading from './components/Loading.vue';

    export default {
        components: {
            Modal : () => import('@/components/modal.vue'),
            Loading
        },
        watch: {
            '$route'(e){
                // TODO: AUTOMATIZAR CARRINHO E LOGIN
                this.popup()
                this.$carrinho.atualizaCarrinho()
                this.$usuario.autoLogin()
                this.$google.paginasView()
                if(this.$axios.getLocal('local_carrinho') && !this.$axios.getLocal('session')){
                    this.$carrinho.carrinho = this.$storage.getLocal('local_carrinho')
                    this.$axios.remove('carrinho')
                }
            },
        },
        methods: {
            popup(){
                if(!this.$axios.getLocal('popup')){
                    this.$config.getConteudo('popup', true)
                    .then(response => {
                        if(response && response[0]){
                            let idconteudo = response[0].idconteudo;
                            this.$axios.get(ENDPOINTS.GETCONTEUDO + idconteudo)
                            .then(res => {
                                if(res.link.indexOf(this.$route.path) != -1 || res.link == null){
                                    const self = this;

                                    setTimeout(() => {
                                        self.$axios.modal.titulo = res.titulo
                                        self.$axios.modal.conteudo = res.html
                                        if(res.subtitulo && res.subtitulo != ''){
                                            let dados = JSON.parse(res.subtitulo)
                                            // colocar no subtitulo um objeto
                                            // { "nomeBotao" : "", "link" : ""}
                                            self.$axios.modal.botao = dados.nomeBotao
                                            self.$axios.modal.link = dados.link
                                        }
    
                                        self.$axios.modal.show = true
                                        self.$axios.setLocal('popup', true)  
                                    }, 1000)
                                }
                            })
                        }
                    })
                }
            }
            
        },

        created() {
            this.$usuario.rotasEmails();
            if(this.$route.query.redirect){
            this.$router.replace(this.$route.query.redirect || '/')
            }
        },

        mounted() {
            this.popup()
            this.$vuetify.goTo(0);

            if(!this.$axios.getLocal('token') && this.$axios.getLocal('session').data == true){
                this.$axios.remove('token');
                this.$axios.remove('session');
            }

            if(this.$axios.getLocal('session') && this.$axios.getLocal('session').data == null) {
                this.$axios.destroy(true)
            }

            if(this.$axios.getLocal('local_carrinho') && !this.$axios.getLocal('session')){
                this.$carrinho.carrinho = this.$storage.getLocal('local_carrinho')
                this.$axios.remove('carrinho')
            }

            window.$root = document.getElementById('app').__vue__

        },
    }
</script>
<style>
    .animate-cookies-button {
        z-index: 99 !important;
    }
    .v-text-field--filled.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot {
        min-height: 50px !important; 
    }
    .v-text-field--outlined fieldset {
        top: -10px !important;
    }
    .v-expansion-panel-header {
        min-height: 30px !important
    }

    @import url('./assets/css/system.css');
    @import url('./assets/css/vue-phone-number-input.css');
    @import url('./style.css');
    .animate-cookies-button{
        bottom:70px !important;
    }
</style>
<style scoped>
.v-snack__content{
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>