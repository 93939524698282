import Vue from 'vue'
Vue.prototype.$facebook = new Vue({
    data() {
        return {}
    },
    methods: {

        init() {

            const script = document.createElement("script");
            script.innerHTML = `
                function(f,b,e,v,n,t,s)
                if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                .callMethod.apply(n,arguments):n.queue.push(arguments)};
                f(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                .queue=[];t=b.createElement(e);t.async=!0;
                .src=v;s=b.getElementsByTagName(e)[0];
                .parentNode.insertBefore(t,s)}(window, document,'script',
                https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '3879663322302153');
                fbq('track', 'PageView');
                `;

            document.head.append(script);
            const img = document.createElement("img");
            img.src =
                "https://www.facebook.com/tr?id=3879663322302153&ev=PageView&noscript=1";
            img.style = "display:none;";
            const noscript = document
                .createElement("noscript")
                .appendChild(img);

            document.head.prepend(noscript);
            document.head.prepend(script);


            if (window.fbq) {
                window.fbq('track', 'PageView');
            }
        },

        pesquisa(search) {
            if (window.fbq) {
                window.fbq('track', 'Search', {
                    search_string: search
                });
            }
        },

        visualizacao(valor) {
            if (window.fbq) {
                window.fbq('track', 'ViewContent', {
                    value: valor,
                    currency: 'BRL'
                });
            }
        },

        addCarrinho(item) {
            if (window.fbq) {
                window.fbq('track', 'AddToCart', {
                    content_ids: [item.idproduto],
                    content_type: item.dscproduto,
                    value: item.preco,
                    currency: 'BRL'
                });
            }
        },

        listaDesejo(item) {
            if (window.fbq) {
                window.fbq('track', 'AddToWishlist', {
                    content_ids: [item.idproduto],
                    content_type: item.dscproduto,
                    value: item.preco,
                    currency: 'BRL'
                });
            }
        },

        checkout() {
            if (window.fbq) {
                window.fbq('track', 'InitiateCheckout');
            }
        },

        addInfoPgto() {
            if (window.fbq) {
                window.fbq('track', 'AddPaymentInfo');
            }
        },

        concluirCompra(resumo) {
            if (window.fbq) {
                window.fbq('track', 'Purchase', {
                    content_ids: resumo.idprodutos,
                    content_type: 'Tickets',
                    value: resumo.valor,
                    currency: 'BRL'
                });
            }
        },

        cadastro(item) {
            if (window.fbq) {
                window.fbq('track', 'Lead', {
                    content_name: item.nome,
                    content_category: item.categoria,
                    value: item.preco,
                    currency: 'BRL'
                });
            }
        },

        completarCadastro() {
            if (window.fbq) {
                window.fbq('track', 'CompleteRegistration');
            }
        },

        eventoPersonalizado(evento, options = { name: 'Cartão de Crédito', value: 0 }) {
            if (window.fbq) {
                window.fbq('track', evento, {
                    name: options.name,
                    value: options.value,
                    currency: 'BRL'
                });
            }
        }
    }
});

setTimeout(() => {
    Vue.prototype.$facebook.init()
}, 1500)

