import Vue from 'vue';
import "../plugins/config.js";
import "../plugins/service/index.js";

Vue.prototype.$regras = new Vue({
    methods: {
        add(item, apre){

            if(item.qtd >= this.$config.regrasDoNegocio.limitCompraDia){
                this.$axios.modalBar('', "Limite de compra atingido")
                return false
            }

            return true
        }
    }
})