
import Vue from 'vue';

// IMPORTA O JSON COM A TRADUÇÃO

import pt from './i18n/pt.json';
import en from './i18n/en.json';
import es from './i18n/es.json';

import router from '@/plugins/router/index.js'


Vue.prototype.$lang_ = new Vue ({

    data() {
        return {
            langs: [
                { lang: 'es-ES', img: 'https://cloudfront.eleventickets.com/copa_america_2021/oficial/frontend/langs/es.jpg' }, 
                { lang: 'pt-BR', img: 'https://cloudfront.eleventickets.com/copa_america_2021/oficial/frontend/langs/pt.jpg' }, 
                { lang: 'en-US', img: 'https://cloudfront.eleventickets.com/copa_america_2021/oficial/frontend/langs/en.jpg' }
            ]
        }
    },

    methods: {

        setLocal(nome, dados){

            // SALVA EM LOCAL STORAGE COM CONTROLE DE SESSÃO, TEMPO DEFAULT É DE 10 minutos, 
            // ISSO SÓ PARA CHAMADAS FIXAS COMO CATEGORIAS, BANNERS

            var today = new Date();
            today.setSeconds(today.getSeconds() + 15);
            return window.sessionStorage.setItem('__' + window.btoa(nome), window.btoa(JSON.stringify( { session: today.getTime(), length: window.btoa(dados).length,  data: dados } )))

        },
    
        getLocal(nome) {
            if('__' + window.btoa(nome) in sessionStorage) {
                return JSON.parse(window.atob(window.sessionStorage.getItem('__' + window.btoa(nome))));
            }
            return false
        },

        remove(nome) {
            if ("__" + window.btoa(nome) in sessionStorage) {
                window.sessionStorage.removeItem("__" + window.btoa(nome));
            }
            return false;
        },

        DEBUG() {

            // TESTA AS LINGUAGENS E BUSCA SE AS CHAVES ESTÃO IGUAIS

            if (JSON.stringify(Object.keys(pt)) != JSON.stringify(Object.keys(en))) {
                let pt_ = Object.keys(pt);
                let en_ = Object.keys(en);
                console.warn('chave PT => EN')
                en_.forEach(i => {
                    console.warn(pt_.find(item => item != i))
                })
                
                console.error('DEBUG: As chaves de tradução pt-BR e en-US estão diferentes, por favor verifique e corrija');
            }

            if (JSON.stringify(Object.keys(pt)) != JSON.stringify(Object.keys(es))) {
                console.error('DEBUG: As chaves de tradução pt-BR e es-ES estão diferentes, por favor verifique e corrija');
            }

            if (JSON.stringify(Object.keys(en)) != JSON.stringify(Object.keys(es))) {
                console.error('DEBUG: As chaves de tradução en-US e en-ES estão diferentes, por favor verifique e corrija');
            }

        },

        getURL() {

            if(window.location.pathname.split('/')[1]){
                let object = window.location.pathname.split('/')[1];

                if(object == 'es'){
                    this.setLocal('lang', 'es-ES')
                }

                if(object == 'en'){
                    this.setLocal('lang', 'en-US')
                } 
                
                if(object == 'pt') {
                    this.setLocal('lang', 'pt-BR')
                }

                return this.getLocal('lang').data
            }

            return false
            // let url = window.location.pathname.split('/')[1];
        },

        getLang() {
            if(this.getLocal('lang')) { 
                return { title: this.getLocal('lang').data.substring(0, 2) , lang: this.getLocal('lang').data, celular: this.getLocal('lang').data.substring(3)  }
            }  

            else {

                let lang = this.getURL();

                if(!lang){

                    if(navigator.browserLanguage) {
    
                        switch (navigator.browserLanguage) {
                            case 'pt-BR':
                                lang = 'pt-BR';
                                break;
                            case 'en-US':
                                lang = 'en-US';
                                break;
                            case 'es-ES':
                                lang = 'es-ES';
                                break;
                            default:
                                lang = 'pt-BR';
                        } 
    
                    }
    
                    else if(navigator.language) {
    
                        switch (navigator.language) {
                            case 'pt-BR':
                                lang = 'pt-BR';
                                break;
                            case 'en-US':
                                lang = 'en-US';
                                break;
                            case 'es-ES':
                                lang = 'es-ES';
                                break;
                            default:
                                lang = 'pt-BR';
                        } 
    
                    } 
                }

                this.setLang(lang)

                return { title: lang.substring(0, 2) , lang: lang, celular: lang.substring(3) }

            }

        },

        setLang(chave) {

            if(window.location.pathname.split('/')[1]) {

                let object = window.location.pathname.split('/')
                let url = chave.substring(0, 2);
                let pass = '';

                for (const chave in object) {
                    if(chave > 1) {
                        pass += '/' + object[chave];
                    }   
                }

                this.setLocal('lang', chave)
                
                if(url == object[1]){
                    document.location.pathname = window.location.pathname;
                } else {
                    document.location.pathname = url
                }


            }

        },

        init() {
            if(this.getLocal('sprint')){
                const rota = this.getLocal('sprint').data
                this.remove('sprint')
                setTimeout(() => {
                    router.push({ name: rota })
                }, 1150)
            }
        },

        _traslaterRouter(chave, text) {
            let traducao = location.pathname.substring(3);

            switch(chave) {
                case 'pt':
                    if(text == '/intenciondecompra' ||  text == '/buy-intention') { traducao = '/intencaodecompra'; }
                    if(text == '/steps') { traducao = '/etapas'; }
                    if(text == '/matches') { traducao = '/jogos'; }
                    if(text == '/matches-presale' || text == '/jogos-venta') { traducao = '/jogos-venda'; }
                    if(text == '/record') { traducao = '/registro'; }
                    if(text == '/profile') { traducao = '/perfil'; }
                    if(text == '/contact') { traducao = '/contato'; }
                    if(text == '/info/privacy' || text == '/info/privacidad') { traducao = '/info/privacidade'; }
                    if(text == '/info/cookies') { traducao = text; }
                    if(text == '/info/siteconditions' || text == '/info/condicesdelsitio') { traducao = '/info/condicoessite'; }
                    if(text == '/info/ticketconditions' || text == '/info/condicionesdelticket') { traducao = '/info/condicoesingresso'; }
                    if(text == '/condiciones' || text == '/conditions') { traducao = '/condicoes'; }
                    if(text == '/reglamentos' || text == '/regulations') { traducao = '/regulamentos'; }
                    break;
                case 'en':
                    if(text == '/intencaodecompra' ||  text == '/intenciondecompra') { traducao = '/buy-intention'; }
                    if(text == '/etapas') { traducao = '/steps'; }
                    if(text == '/jogos') { traducao = '/matches'; }
                    if(text == '/jogos-venta' || text == '/jogos-venda') { traducao = '/matches-presale'; }
                    if(text == '/registro') { traducao = '/record'; }
                    if(text == '/perfil') { traducao = '/profile'; }
                    if(text == '/contato') { traducao = '/contact'; }
                    if(text == '/info/privacidade' || text == '/info/privacidad') { traducao = '/info/privacy'; }
                    if(text == '/info/cookies') { traducao = text; }
                    if(text == '/info/condicoessite' || text == '/info/condicesdelsitio') { traducao = '/info/siteconditions'; }
                    if(text == '/info/condicoesingresso' || text == '/info/condicionesdelticket') { traducao = '/info/ticketconditions'; }
                    if(text == '/condiciones' || text == '/condicoes') { traducao = '/conditions'; }
                    if(text == '/reglamentos' || t$axiosext == '/regulamentos') { traducao = '/regulations'; }
                    break;
                case 'es':
                    if(text == '/intencaodecompra' ||  text == '/buy-intention') { traducao = '/intenciondecompra'; }
                    if(text == '/steps') { traducao = '/etapas'; }
                    if(text == '/matches') { traducao = '/jogos'; }
                    if(text == '/jogos-venda' || text == '/matches-presale') { traducao = '/jogos-venta'; }
                    if(text == '/record') { traducao = '/registro'; }
                    if(text == '/profile') { traducao = '/perfil'; }
                    if(text == '/contact') { traducao = '/contato'; }
                    if(text == '/info/privacy' || text == '/info/privacidade') { traducao = '/info/privacidad'; }
                    if(text == '/info/cookies') { traducao = text; }
                    if(text == '/info/siteconditions' || text == '/info/condicoessite') { traducao = '/info/condicesdelsitio'; }
                    if(text == '/info/ticketconditions' || text == '/info/condicoesingresso') { traducao = '/info/condicionesdelticket'; }
                    if(text == '/condicoes' || text == '/conditions') { traducao = '/condiciones'; }
                    if(text == '/regulations' || text == '/regulamentos') { traducao = '/reglamentos'; }
                    break;
            }

            let pathname = chave + traducao;

            if(document.location.pathname != pathname) {
                document.location.pathname = pathname;
            }
  
        },

        _traslater(chave) {

            let lang = null;

            // CASO TENHA SELECIONADO NO MENU

            if(this.getLocal('lang')) {

                const language = this.getLocal('lang').data;

                switch (language) {
                    case 'pt-BR':
                        lang = pt;
                        break;
                    case 'en-US':
                        lang = en;
                        break;
                    case 'es-ES':
                        lang = es;
                        break;
                    default:
                        lang = pt
                }

            } 
            
            else {

                // SE NÃO EXISTE SELEÇÃO ELE PEGA O DEFAULT DO NAVEGADOR

                if(navigator.browserLanguage) {

                    switch (navigator.browserLanguage) {
                        case 'pt-BR':
                            lang = pt;
                            break;
                        case 'en-US':
                            lang = en;
                            break;
                        case 'es-ES':
                            lang = es;
                            break;
                        default:
                            lang = es;
                    } 

                }

                else if(navigator.language) {

                    switch (navigator.language) {
                        case 'pt-BR':
                            lang = pt;
                            break;
                        case 'en-US':
                            lang = en;
                            break;
                        case 'es-ES':
                            lang = es;
                            break;
                        default:
                            lang = pt;
                    } 

                } 
                
                else {

                    lang = pt;

                }

            }

            if(lang['' + chave]){

                // CASO EXISTA A CHAVE

                return lang['' + chave]
                
            }

            else {

                // CASO NÃO ENCONTRE A CHAVE ELE RETORNA ESSA MENSAGEM

                return `[${chave}]`;   

            }
        }
    }
})

Vue.prototype.$t_ = function(chave) {
    return Vue.prototype.$lang_._traslater(chave);   
}

