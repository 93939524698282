import Vue from 'vue';
import moment from 'moment';

Vue.prototype.$moment = moment

Vue.filter('moeda', function(v) {
    var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
    });

    let preco = formatter.format(v).split(',')

    return preco[0]
});

Vue.filter('centavos', function(v) {
    var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
    });

    let preco = formatter.format(v).split(',')

    return preco[1]
});

Vue.filter('moedaDolar', function(v) {
    var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
    });
    return formatter.format(v);
});

Vue.filter('dia', function(v) {
    let res = moment(v).format('DD')
    return res
});

Vue.filter('mesano', function(v) {
    let res = moment(v).format('MM/YYYY')
    return res
});

Vue.filter('hora', function(v) {
    let res = moment(v).format('h:mm')
    return res
});

Vue.filter('data',function(v){
    let res = moment(v).format('DD/MM/YYYY')
    return res
});

Vue.filter('uppercase', function(v) {
    if(v){
        return v.toUpperCase();
    }
    return '[NAME_ESTATIUM_NO_PARAM]'
})

Vue.filter('lowercase', function(v) {
    return v.toString().toLowerCase();
})

Vue.filter('trim', function(v) {
    return v.toString().trim();
})

Vue.filter('inicial', function(v) {
    return v.toString().charAt(0);
})

Vue.filter('removeLetras', function(v) {
    return v.toString().replace(/\D+/g, '');
})

Vue.filter('removeAcentos', function(v) {
    return v.toString().normalize("NFD").replace(/[^a-zA-Zs]/g, "");
})

Vue.filter('tamanho', function(v) {
    return v.toString().length;
})
