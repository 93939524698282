<template>
    <div v-if="$root.loading" class="loading">
        <img src="@/assets/images/Loading.gif" alt="Carregando..." class="loading_img">
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
    .loading {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(101, 101, 101, 0.459);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }
    .loading_img {
        width: 70px; 
    }
</style>