import Vue from 'vue';
import { googleTagManager, GA4, otmizacao } from '../../package.json'

let gtmkey = ''
if(process.env.NODE_ENV === 'production') {
    gtmkey = googleTagManager.producao
}
if(process.env.NODE_ENV === 'development') {
    gtmkey = googleTagManager.homologacao
}

import "../plugins/config.js";
import "../plugins/service/index.js";
import "@/controllers/Sac.controller.js";
import GAUniversal from "@/controllers/GAUniversal.mixeds.js";

/**
 * @steps 3;
 * 1 - Entrou na página do Carrinho
 * 2 - Iniciou o Pagamento
 * 3 - Finalizou o Pagamento
 */

Vue.prototype.$google = new Vue ({
    mixins: [GAUniversal],
    data() {
        return{
            gtmKey: gtmkey
        }
    },
    methods: {

        async init() {
            // https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce?hl=pt_br

             this.initUniversal(this.gtmKey)

            var varCounter = 0;
            const id = await this.getBrowserUUID()

            let gtm = document.createElement("script");
                gtm.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${GA4}`)
                gtm.setAttribute('async', true)
            document.head.prepend(gtm);

            let dataGtm = document.createElement("script");
                dataGtm.innerHTML = `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
        
                    gtag('config', '${GA4}', { 
                        'user_id': '${id}',
                        'debug_mode': false,
                        'page_title': '${document.title}',
                        'currency': 'BRL',
                    });
                `
            document.head.append(dataGtm);
            
            // const self = this
            let time = setInterval(function(){
                if(varCounter >= 10){
                    clearInterval(time);
                }
                
                if(typeof window.gtag != 'undefined'){
                    clearInterval(time);

                    window.gtag('set', 'allow_ad_personalization_signals', false );
                    window.gtag('set', 'allow_google_signals', false);

                    console.warn('GA4 implementado')
                }

                varCounter++
            }, 1000)

        },

        login(metodo = 'Superingresso', tipo = 'Público geral'){
            window.gtag('event', 'login', {'method': metodo});
            window.gtag('event', 'sign_in', { 'send_to': tipo });
        },

        paginasView(){
            if(typeof window.gtag == 'undefined'){ return }
            const root = document.getElementById('app').__vue__
            window.gtag('config', GA4, {
                'page_title' : root.$route.name,
                'page_path': root.$route.path
            });
        },

        tratarProdutos(produtos = []){

            let items = []

            produtos.forEach((item) => {
                
                let i = {
                    "id": item.idprod || item.idproduto,
                    "name": item.dscproduto,
                    "list_name": item.dscproduto,
                    "brand": 'Eleventickets',
                    "category": "Tickets/Online",
                    "quantity": item.qtd || 1,
                    "price": Number(item.total || item.preco_ctaxa).toFixed(2)
                }

                items.push(i)
                
            })

            items.forEach((item, index) => {
                item.list_position = index
            })

            return items
        },

        
        purchaseData(produtos = []){
            let total = 0
            let taxa  = 0

            produtos.forEach((item) => {
                total += Number(item.total)
                taxa += Number(item.taxa)
            })

            return { total, taxa }
        },

        addCarrinho(produtos = []){
            if(typeof window.gtag == 'undefined'){ return }
            produtos = this.tratarProdutos(produtos)
            window.gtag('event', 'view_item_list',  { "items": produtos });
            window.gtag('event', 'select_content',  { "content_type": "tickets", "items": produtos });
            window.gtag('event', 'add_to_cart',  { "items": produtos }); // correto
        },

        removeCarrinho(produtos = []){
            if(typeof window.gtag == 'undefined'){ return }
            produtos = this.tratarProdutos(produtos)
            window.gtag('event', 'remove_from_cart',  { "items": produtos });
        },

        checkoutStepOne(produtos = [], cupom = ''){
            if(typeof window.gtag == 'undefined'){ return }
            produtos = this.tratarProdutos(produtos)
            window.gtag('event', 'begin_checkout',  { "items": produtos, "coupon": cupom });
            console.warn('Checkout Begin')
            //this.getCheckoutStepUniversal(1, 'Checkout')
        },

        checkoutStepPagamento(produtos = [], cupom = ''){
            if(typeof window.gtag == 'undefined'){ return }
            produtos = this.tratarProdutos(produtos)
            window.gtag('event', 'checkout_progress',  { "items": produtos, "coupon": cupom });
            console.warn('Checkout Pagamento')
            //this.getCheckoutStepUniversal(2, 'Checkout Pagamento')
        },

        checkoutStepFinalizarCarrinho(produtos = [], idmovimentacao = new Date().getTime(), cupom = ''){
            if(typeof window.gtag == 'undefined'){ return }

            const config     = this.purchaseData(produtos)
            const plataforma = this.mobile() ? 'Mobile': 'Desktop'
            produtos         = this.tratarProdutos(produtos)

            window.gtag('event', 'set_checkout_option', {
                "checkout_step": 1,
                "checkout_option": "shipping method",
                "value": 3
            });

            window.idmovimentacao = idmovimentacao
            window.config         = config
            window.produtos       = produtos
            window.cupom          = cupom

            window.gtag('event', 'purchase', {
                "transaction_id": idmovimentacao,
                "affiliation": `Superingresso - ${plataforma}`,
                "value": (config.total).toFixed(2).toString(),
                "currency": "BRL",
                "tax": (config.taxa || 0).toFixed(2).toString(),
                "shipping": (0).toFixed(2).toString(),
                "coupon": cupom,
                "items": produtos
            });

            //this.enviarConversaoUniversal(produtos)
            this.getCheckoutStepUniversal(3, 'Checkout Pagamento')

            console.warn('Finalizando Purchase')

            setTimeout(() => {
                this.$axios.rota('MeusPedidos')
            }, 1000)
        },

        reembolsoParcial(produtos = [], idmovimentacao = new Date().getTime()){

            if(typeof window.gtag == 'undefined'){ return }
            
            const config     = this.purchaseData(produtos)
            const plataforma = this.mobile() ? 'Mobile': 'Desktop'
            produtos         = this.tratarProdutos(produtos)

            window.gtag('event', 'purchase', {
                "transaction_id": idmovimentacao,
                "affiliation": `Superingresso - ${plataforma}`,
                "value": config.total,
                "currency": "BRL",
                "tax": config.taxa || 0,
                "shipping": 0,
                "items": produtos
            });
        }
    }
});

Vue.prototype.$google.init()